import React, {useState, useEffect, useRef} from "react";
import api from "../../api/credentials";
import {Link, navigate} from "gatsby";

import MuiAlert from "@mui/material/Alert";
import {Snackbar} from "@material-ui/core";
import axios from "axios";
import {es} from 'date-fns/locale';
const {format} = require("date-fns");

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

const ServiceDetailsSidebar = ({service}) => {
    const textInput = useRef(null);
    const role = "button";
    let selectHourText = "Seleccione una opción";
    const {id, max, min} = service;
    const [fields, setFields] = useState(() => {
        if (localStorage.getItem("fields") !== null) {
            return JSON.parse(localStorage.getItem("fields"));
        } else {
            return [];
        }
    });
    const [newOrder, setNewOrder] = useState({
        facility_id: '',
        service_id: id,
        units: min ? min : 1,
        zone_id: '',
        specialDate: '',
        serviceDate: '',
        serviceHour: "",
        options: [],
        price: service.price_type === "fixed" ? service.price : 0,
        expedient: "",
        iban: "",
        notes: "",
        amount: "",
        discount: "",
        dest_user: "",
        discount_amount: "",
        complement: "",
        complement_amount: "",
        extra_fields: [],
        user_type: "particular",
        payment_type: service.price_type === "fixed" && service.price == 0 ? "free" : "card",
    });

    const [error, setError] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [success, setSuccess] = useState(null);
    const [creating, setCreating] = useState(null);
    const [creatingCart, setCreatingCart] = useState(null);
    const [familiarDisable, setFamiliarDisable] = useState(false);
    const [documentDisable, setDocumentDisable] = useState(false);
    const [documents, setDocuments] = useState(null);
    const [familiars, setFamiliars] = useState([]);
    const [familiar, setFamiliar] = useState(null);
    const [required, setRequired] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (
            (newOrder.serviceDate != null && service.with_booking && service.with_time && service.facility.slot)
            ||
            (newOrder.serviceDate != null && service.with_multiple_booking && service.with_time && newOrder.facility_id)
        ) {
            axios
                .get(`${process.env.API_URL}/getAvailableDates`, {
                    params: {
                        facility_id: newOrder.facility_id,
                        service_id: newOrder.service_id,
                        units: newOrder.service_id,
                        serviceDate: newOrder.serviceDate,
                        multiple: service.with_multiple_booking,
                    },
                })
                .then((response) => {
                    const requestOptions = [];
                    for (const val of response.data.intervals) {
                        const option = {value: val, label: val};
                        requestOptions.push(option);
                    }
                    if (response.data.intervals.length == 0) {
                        selectHourText = "No hay horarios disponibles";
                    } else {
                        selectHourText = "Seleccione una opción";
                    }
                    setNewOrder((newOrder) => ({...newOrder, options: requestOptions}));
                    textInput.current?.focus();
                })
                .catch((error) => {
                    setNewOrder((newOrder) => ({...newOrder, serviceDate: ''}));
                    setError(error);
                });
        }
    }, [newOrder.serviceDate]);
    function objLength(obj) {
        var i = 0;
        for (var x in obj) {
            if (obj.hasOwnProperty(x)) {
                i++;
            }
        }
        return i;
    }
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    useEffect(() => {
        api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
            api.post(`${process.env.API_URL}/familiars`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    var passed = false;
                    if (response.data.familiars.length == 0 && service.familiar_use == 1) {
                        setFamiliarDisable(true);
                    } else {
                        passed = true;
                    }
                    setFamiliars(response.data.familiars);
                    setDocuments(response.data.documents);
                    setUser(response.data.user);
                    setNewOrder((newOrder) => ({...newOrder, dest_user: response.data.user.id}));
                    if (service.profile_fields !== null) {
                        var req = unserialize(service.profile_fields);
                        if (objLength(req) == 0) {
                            passed = true;
                        } else {
                            var familyBook_image = response.data.user.media.filter((media) => media.collection_name === "familyBook");
                            var largeFamily_image = response.data.user.media.filter((media) => media.collection_name === "largeFamily");
                            var disabilityCertificate_image = response.data.user.media.filter((media) => media.collection_name === "disabilityCertificate");
                            var idCard_image = response.data.user.media.filter((media) => media.collection_name === "idCard");

                            var text = [];
                            Object.keys(req).forEach(function (key) {
                                if ((req[key] == 1 && familyBook_image.length == 0) || (req[key] == 2 && largeFamily_image.length == 0) || (req[key] == 3 && disabilityCertificate_image.length == 0) || (req[key] == 4 && idCard_image.length == 0)) {
                                    text.push(response.data.documents.filter((doc) => parseInt(doc.id) === parseInt(req[key]))[0].name);

                                    setDocumentDisable(true);
                                    passed = false;
                                }
                            });

                            setRequired(text);
                        }
                    }

                    if (passed) {
                        setShowForm(true);
                    }
                })
                .catch((error) => {
                    console.log(error);

                    switch (error.response.status) {
                        case 401: // Not logged in
                        case 419: // Session expired
                            localStorage.removeItem("user");
                            window.dispatchEvent(new Event("removeUser"));
                            setUser(null);
                            break;

                        default:
                            setFamiliarDisable(false);
                    }
                });
        });
    }, []);

    function unserialize(data) {
        // Takes a string representation of variable and recreates it
        //
        // version: 810.114
        // discuss at: http://phpjs.org/functions/unserialize
        // +     original by: Arpad Ray (mailto:arpad@php.net)
        // +     improved by: Pedro Tainha (http://www.pedrotainha.com)
        // +     bugfixed by: dptr1988
        // +      revised by: d3x
        // +     improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // %            note: We feel the main purpose of this function should be to ease the transport of data between php & js
        // %            note: Aiming for PHP-compatibility, we have to translate objects to arrays
        // *       example 1: unserialize('a:3:{i:0;s:5:"Kevin";i:1;s:3:"van";i:2;s:9:"Zonneveld";}');
        // *       returns 1: ['Kevin', 'van', 'Zonneveld']
        // *       example 2: unserialize('a:3:{s:9:"firstName";s:5:"Kevin";s:7:"midName";s:3:"van";s:7:"surName";s:9:"Zonneveld";}');
        // *       returns 2: {firstName: 'Kevin', midName: 'van', surName: 'Zonneveld'}

        var error = function (type, msg, filename, line) {
            throw new window[type](msg, filename, line);
        };
        var read_until = function (data, offset, stopchr) {
            var buf = [];
            var chr = data.slice(offset, offset + 1);
            var i = 2;
            while (chr != stopchr) {
                if (i + offset > data.length) {
                    error("Error", "Invalid");
                }
                buf.push(chr);
                chr = data.slice(offset + (i - 1), offset + i);
                i += 1;
            }
            return [buf.length, buf.join("")];
        };
        var read_chrs = function (data, offset, length) {
            var buf = [];
            for (var i = 0; i < length; i++) {
                var chr = data.slice(offset + (i - 1), offset + i);
                buf.push(chr);
            }
            return [buf.length, buf.join("")];
        };
        var _unserialize = function (data, offset) {
            if (!offset) offset = 0;
            var buf = [];
            var dtype = data.slice(offset, offset + 1).toLowerCase();

            var dataoffset = offset + 2;
            var typeconvert = new Function("x", "return x");
            var chrs = 0;
            var datalength = 0;

            switch (dtype) {
                case "i":
                    typeconvert = new Function("x", "return parseInt(x)");
                    var readData = read_until(data, dataoffset, ";");
                    var chrs = readData[0];
                    var readdata = readData[1];
                    dataoffset += chrs + 1;
                    break;
                case "b":
                    typeconvert = new Function("x", "return (parseInt(x) == 1)");
                    var readData = read_until(data, dataoffset, ";");
                    var chrs = readData[0];
                    var readdata = readData[1];
                    dataoffset += chrs + 1;
                    break;
                case "d":
                    typeconvert = new Function("x", "return parseFloat(x)");
                    var readData = read_until(data, dataoffset, ";");
                    var chrs = readData[0];
                    var readdata = readData[1];
                    dataoffset += chrs + 1;
                    break;
                case "n":
                    readdata = null;
                    break;
                case "s":
                    var ccount = read_until(data, dataoffset, ":");
                    var chrs = ccount[0];
                    var stringlength = ccount[1];
                    dataoffset += chrs + 2;

                    var readData = read_chrs(data, dataoffset + 1, parseInt(stringlength));
                    var chrs = readData[0];
                    var readdata = readData[1];
                    dataoffset += chrs + 2;
                    if (chrs != parseInt(stringlength) && chrs != readdata.length) {
                        error("SyntaxError", "String length mismatch");
                    }
                    break;
                case "a":
                    var readdata = {};

                    var keyandchrs = read_until(data, dataoffset, ":");
                    var chrs = keyandchrs[0];
                    var keys = keyandchrs[1];
                    dataoffset += chrs + 2;

                    for (var i = 0; i < parseInt(keys); i++) {
                        var kprops = _unserialize(data, dataoffset);
                        var kchrs = kprops[1];
                        var key = kprops[2];
                        dataoffset += kchrs;

                        var vprops = _unserialize(data, dataoffset);
                        var vchrs = vprops[1];
                        var value = vprops[2];
                        dataoffset += vchrs;

                        readdata[key] = value;
                    }

                    dataoffset += 1;
                    break;
                default:
                    error("SyntaxError", "Unknown / Unhandled data type(s): " + dtype);
                    break;
            }
            return [dtype, dataoffset - offset, typeconvert(readdata)];
        };
        return _unserialize(data, 0)[2];
    }
    const handleChange = (event) => {
        setError(null);

        const newObject = Object.assign({}, newOrder);
        switch (event.target.name) {
            case "dest_user":
                Object.assign(newObject, {discount: ""});
                if (String(event.target.value).indexOf("familiar") === -1) {
                    setFamiliar(null)
                } else {
                    var porciones = event.target.value.split('familiar-');
                    let selectedFam = familiars.find((element) => parseInt(element.id) === parseInt(porciones[1]))
                    setFamiliar(selectedFam);
                }

                break;
            case "discount":
                if (typeof event.target.value !== undefined && event.target.value !== "") {
                    var dest = null
                    if (String(newObject.dest_user).indexOf("familiar") === -1) {
                        dest = 1;
                    } else {
                        dest = 2;
                    }
                    let selected = service.discounts.find((item) => item.id === parseInt(event.target.value));
                    var errorDiscount = false;
                    //Comprobamos si hay algun documento obligatorio en el descuento
                    if (selected?.profile_fields !== null) {
                        var req = unserialize(selected?.profile_fields);

                        if (objLength(req) == 0) {
                        } else {
                            if (dest == 1) {
                                var familyBook_image = user.media.filter((media) => media.collection_name === "familyBook");
                                var largeFamily_image = user.media.filter((media) => media.collection_name === "largeFamily");
                                var disabilityCertificate_image = user.media.filter((media) => media.collection_name === "disabilityCertificate");
                                var idCard_image = user.media.filter((media) => media.collection_name === "idCard");

                                Object.keys(req).forEach(function (key) {
                                    if ((req[key] == 1 && familyBook_image.length == 0) || (req[key] == 2 && largeFamily_image.length == 0) || (req[key] == 3 && disabilityCertificate_image.length == 0) || (req[key] == 4 && idCard_image.length == 0)) {
                                        event.target.value = "";
                                        Object.assign(newObject, {discount_amount: ""});

                                        setError("Para poder aplicar este descuento debe rellenar el siguiente documento en su perfil: " + documents.filter((doc) => parseInt(doc.id) === parseInt(req[key]))[0].name);
                                        errorDiscount = true;
                                        return false;
                                    }
                                });
                            } else {
                                var familyBook_image = user.media.filter((media) => media.collection_name === "familyBook");
                                var largeFamily_image = user.media.filter((media) => media.collection_name === "largeFamily");
                                var disabilityCertificate_image = familiar.media.filter((media) => media.collection_name === "disabilityCertificate");
                                var idCard_image = familiar.media.filter((media) => media.collection_name === "idCard");
                                Object.keys(req).forEach(function (key) {
                                    if ((req[key] == 1 && familyBook_image.length == 0) || (req[key] == 2 && largeFamily_image.length == 0) || (req[key] == 3 && disabilityCertificate_image.length == 0) || (req[key] == 4 && idCard_image.length == 0)) {
                                        event.target.value = "";
                                        Object.assign(newObject, {discount_amount: ""});

                                        setError("Para poder aplicar este descuento debe rellenar el siguiente documento en su perfil / familiar: " + documents.filter((doc) => parseInt(doc.id) === parseInt(req[key]))[0].name);
                                        errorDiscount = true;
                                        return false;
                                    }
                                });
                            }

                        }
                    }
                    if (selected?.from_age !== null && selected?.to_age !== null) {
                        if (dest == 1) {
                            if (user.birthday === null || user.birthday === "") {
                                event.target.value = "";
                                Object.assign(newObject, {discount_amount: ""});
                                setError("Para poder aplicar este descuento debe completar la fecha de nacimiento en su perfil");
                                errorDiscount = true;
                                return false;
                            } else {
                                var userage = getAge(user.birthday);
                                if (userage > selected?.to_age || userage < selected?.from_age) {
                                    event.target.value = "";
                                    Object.assign(newObject, {discount_amount: ""});
                                    setError("Este descuento está limitado a usuarios cuya edad está entre " + selected?.from_age + " y " + selected?.to_age + " años");
                                    errorDiscount = true;

                                    return false;
                                }
                            }
                        } else {
                            if (familiar.birthday === null || familiar.birthday === "") {
                                event.target.value = "";
                                Object.assign(newObject, {discount_amount: ""});
                                setError("Para poder aplicar este descuento debe completar la fecha de nacimiento del familiar");
                                errorDiscount = true;
                                return false;
                            } else {
                                var userage = getAge(familiar.birthday);
                                if (userage > selected?.to_age || userage < selected?.from_age) {
                                    event.target.value = "";
                                    Object.assign(newObject, {discount_amount: ""});
                                    setError("Este descuento está limitado a usuarios cuya edad está entre " + selected?.from_age + " y " + selected?.to_age + " años");
                                    errorDiscount = true;

                                    return false;
                                }
                            }
                        }

                    }

                    if (!errorDiscount) {
                        Object.assign(newObject, {discount_amount: selected?.percentage});
                    }
                } else {
                    Object.assign(newObject, {discount_amount: ""});
                }
                break;
            case "complement":
                if (typeof event.target.value !== undefined && event.target.value !== "") {
                    let selected = service.complements.find((item) => item.id === parseInt(event.target.value));
                    Object.assign(newObject, {complement_amount: selected?.price});
                } else {
                    Object.assign(newObject, {complement_amount: ""});
                }
                break;
            case "amount":
                break;
            case "specialDate":
                break;
            case "zone_id":
                break;
            case "serviceDate":
                Object.assign(newObject, {serviceHour: "", options: []});
                break;
            case "serviceHour":
                break;
            case "units":
                let value = max && parseInt(event.target.value) > parseInt(max) ? max : parseInt(event.target.value);
                Object.assign(newObject, {[event.target.name]: value});

                break;
            case 'facility_id':
                Object.assign(newObject, {serviceHour: "", serviceDate: '',options: []});
                break;
        }

        if (event.target.name.includes("extra-")) {
            var storageFields = JSON.parse(localStorage.getItem("fields"));
            if (!storageFields) {
                storageFields = [];
            }
            var data = {};
            data.id = event.target.getAttribute("data-id");
            data.name = event.target.getAttribute("data-name");
            data.value = event.target.value;
            var storageIndex = storageFields.findIndex((x) => x.name === event.target.getAttribute("data-name"));
            if (storageIndex !== -1) {
                storageFields[storageIndex] = data;
            } else {
                storageFields.push(data);
            }
            localStorage.setItem("fields", JSON.stringify(storageFields));
            setFields(storageFields);
            var parts = event.target.name.split("-");
            if (parts.length == 2) {
                var obj = {};
                obj.id = parts[1];
                obj.value = event.target.value;
                newObject.extra_fields = newObject.extra_fields.filter((item) => item.id !== parts[1]);
                newObject.extra_fields.push(obj);
            }
        } else {
            Object.assign(newObject, {[event.target.name]: event.target.value});
        }

        //calculamos el precio
        var actual_price = 0;
        switch (service.price_type) {
            case "fixed":
                actual_price = newObject.units * service.price;

                break;
            case "free":
                actual_price = newObject.amount * newObject.units;

                break;
            case "percentage":
                actual_price = newObject.amount * newObject.units * (service.price / 100);

                break;
        }
        if (newObject.complement !== "") {
            actual_price = actual_price + newObject.complement_amount;
        }
        Object.assign(newObject, {price: actual_price ?? 0});

        // quitamos el descuento
        var total_discount = newObject.discount_amount ? (actual_price * newObject.discount_amount) / 100 : 0;
        Object.assign(newObject, {price: actual_price - total_discount});
        console.log(newObject);
        setNewOrder(newObject);
    };
    const renderPrice = (service) => {
        switch (service.price_type) {
            case "fixed":
                return (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Precio</span>
                            {newOrder.price == 0 ? "Gratis" : formatter.format(newOrder.price)}
                        </div>
                    </>
                );
            case "free":
                return (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Importe</span>
                            <input
                                type="number"
                                name="amount"
                                className="form-control w-50"
                                min="0.01"
                                value={newOrder.amount}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p>
                                <small>Introduzca la cantidad que desea abonar</small>
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Total</span>
                            {formatter.format(newOrder.price)}
                        </div>
                    </>
                );
            case "percentage":
                return (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Cantidad</span>
                            <input
                                type="number"
                                name="amount"
                                className="form-control w-50"
                                min="0.01"
                                value={newOrder.amount}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p>
                                <small>Introduzca la cantidad sobre la cual abonará el {service.price} %</small>
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Total</span>
                            {formatter.format(newOrder.price)}
                        </div>
                    </>
                );
            default:
                return <>{formatter.format(newOrder.price)}</>;
        }
    };
    const renderField = (field) => {
        switch (field.type) {
            case "input":
                return (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <input
                                type="text"
                                name={`extra-` + field.id}
                                data-id={field.id}
                                data-name={field.label.trim()}
                                value={fields?.find((item) => item.name === field.label.trim())?.value}
                                className="form-control w-100"
                                onChange={handleChange}
                            />
                        </div>
                    </>
                );
            case "textarea":
                return (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <textarea
                                name={`extra-` + field.id}
                                data-id={field.id}
                                data-name={field.label.trim()}
                                value={fields?.find((item) => item.name === field.label.trim())?.value}
                                className="form-control w-100"
                                onChange={handleChange}
                                rows="8"
                            ></textarea>
                        </div>
                    </>
                );
            case "select":
                return (
                    <>
                        <div className="d-flex justify-content-between align-items-center">
                            <select
                                name={`extra-` + field.id}
                                data-id={field.id}
                                data-name={field.label.trim()}
                                value={fields?.find((item) => item.name === field.label.trim())?.value}
                                className="form-select"
                                onChange={handleChange}
                            >
                                <option>Seleccione una opción</option>
                                {JSON.parse(field.values).map((item, index) => (
                                    <option
                                        key={index}
                                        value={index}
                                    >
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                );
            default:
                return <></>;
        }
    };

    const addToCart = () => {
        setError(null);
        setCreatingCart(true);
        // if (service.expedientable && (newOrder.expedient === "" || newOrder.expedient === null)) {
        //     setError('Debe rellenar el Nº de expediente')
        //     setCreating(false)
        //     return false;

        // }

        var serviceFields = fields.filter(function (el) {
            const pluck = (arr, key) => arr.map((i) => i[key]);
            const labels = pluck(service.fields, "label");

            return labels.includes(el.name);
        });
        newOrder.extra_fields = serviceFields;
        // if (min && selected_units < min) {
        //     setError('Debe comprar mínimo ' + min + ' unidad/es por pedido')
        //     setNewOrder(newOrder => ({ ...newOrder, units: min ? min : 1 }))
        //     setCreatingCart(false)
        //     return false;

        // }
        if (max && newOrder.units > max) {
            setError("Este servicio está limitado a " + max + " unidad/es por pedido");
            setNewOrder((newOrder) => ({...newOrder, units: min ? min : 1}));
            setCreatingCart(false);
            return false;
        }
        if ((service.price_type === "free" || service.price_type === "percentage") && (!newOrder.amount || newOrder.amount <= 0)) {
            setError("Debe rellenar el importe");
            setCreatingCart(false);
            return false;
        }
        if (service.with_multiple_booking && (newOrder.facility_id === "" || newOrder.facility_id === null)) {
            setError("Debe seleccionar una instalación");
            setCreatingCart(false);

            return false;
        }
        if(service.with_booking && service.facility?.slot && !newOrder.facility_id) {
            newOrder.facility_id = service.facility_id
        }
        if (service.with_booking && (newOrder.serviceDate === "" || newOrder.serviceDate === null)) {
            setError("Debe seleccionar fecha para la reserva");
            setCreatingCart(false);

            return false;
        }
        if (service.with_booking && service.with_time && ((!service.with_multiple_booking && service.facility.slot) || (service.with_multiple_booking)) && (newOrder.serviceHour === "" || newOrder.serviceHour === null)) {
            setError("Debe seleccionar hora para la reserva");
            setCreatingCart(false);

            return false;
        }
        if (localStorage.getItem("cart") !== null) {
            var allcart = JSON.parse(localStorage.getItem("cart"));
        } else {
            var allcart = [];
        }
        api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
            api.post(`${process.env.API_URL}/cart`, {cart: newOrder, allcart: allcart})
                .then((response) => {
                    if (localStorage.getItem("cart") !== null) {
                        var cart = JSON.parse(localStorage.getItem("cart"));
                    } else {
                        var cart = [];
                    }
                    cart.push(newOrder);
                    localStorage.setItem("cart", JSON.stringify(cart));

                    setSuccess("Añadido a la cesta correctamente");
                    window.dispatchEvent(new Event("storage"));
                    window.location.href = "/cart";
                })
                .catch((error) => {
                    setCreatingCart(false);

                    switch (error.response.status) {
                        case 401: // Not logged in
                        case 419: // Session expired
                            localStorage.removeItem("user");
                            window.dispatchEvent(new Event("removeUser"));
                            navigate("/signin");
                            break;

                        default:
                            setError(error.response.data.error);
                    }
                });
        });
    };
    const handleClose = () => {
        setError(null);
    };
    const handleCloseSuccess = () => {
        setSuccess(null);
    };
    const formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
    });

    return (
        <div className="services-details-info">
            <ul className="info">
                <li className="price">{renderPrice(service)}</li>
                {!!(familiarDisable && user) && (
                    <>
                        <div className="alert alert-danger">
                            Este es un servicio de uso familiar. Para poder adquirirlo debe dar de alta sus familiares <Link to="/profile?tab=tab2">aquí</Link>
                        </div>
                    </>
                )}

                {!!(documentDisable && user) && (
                    <>
                        <div className="alert alert-danger">
                            Adjunte los siguientes documentos para poder adquirir este servicio:
                            <ul>
                                {required.map((item) => (
                                    <li key={item}>
                                        <Link to="/profile?tab=tab3">{item}</Link>
                                    </li>
                                ))}
                            </ul>
                            <br />
                        </div>
                    </>
                )}
                {!familiarDisable && showForm && user ? (
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Usuario</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                            <small>Usuario que va a usar el servicio. Puede gestionar sus familiares en <Link to="/profile">su perfil</Link></small>
                        </div>
                        <select
                            className="form-select w-100"
                            ref={textInput}
                            name="dest_user"
                            value={newOrder.dest_user}
                            onChange={handleChange}
                        >
                            <option value={user.id}>{user.name} {user.surname}</option>
                            {familiars.map((item) => (
                                <option
                                    key={`familiar-` + item.id}
                                    value={`familiar-` + item.id}
                                >
                                    {item.name} {item.surname}
                                </option>
                            ))}
                        </select>
                    </li>
                ) : null}
                {!familiarDisable && showForm && user && service.discounts.length > 0 ? (
                    <li className="discount">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Descuento</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <select
                                className="form-select w-100"
                                ref={textInput}
                                name="discount"
                                value={newOrder.discount}
                                onChange={handleChange}
                            >
                                <option value="">Seleccione una opción</option>
                                {service.discounts.map((item) => (
                                    <option
                                        key={`discount-` + item.id}
                                        value={item.id}
                                    >
                                        {item.name} ({item.percentage}%)
                                    </option>
                                ))}
                            </select>
                        </div>
                        {newOrder.discount && (
                            <>
                                <small>Asegúrese de seleccionar el descuento correcto ya que éste será verficado por la administración</small>
                            </>
                        )}
                    </li>
                ) : null}
                {!familiarDisable && showForm && user && service.complements.length > 0 ? (
                    <li className="complement">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Complemento</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <select
                                className="form-select w-100"
                                ref={textInput}
                                name="complement"
                                onChange={handleChange}
                            >
                                <option value="">Seleccione una opción</option>
                                {service.complements.map((item) => (
                                    <option
                                        key={`complement-` + item.id}
                                        value={item.id}
                                    >
                                        {item.name} ({item.price}€)
                                    </option>
                                ))}
                            </select>
                        </div>
                    </li>
                ) : null}
                {!!(!familiarDisable && showForm && user && service.seat_booking) && (
                    <>
                        <li className="date">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Fecha</span>
                                <select
                                    className="form-control w-75"
                                    name="specialDate"
                                    ref={textInput}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {service.facility.permanent_bookings.map((item) => (
                                        <option
                                            key={`reserva` + item.id}
                                            value={item.id}
                                        >
                                            {format(new Date(item.day), "dd/MM/yyyy", {locale: es}) + ` (${item.start.split(':').slice(0, 2).join(':')}-${item.end.split(':').slice(0, 2).join(':')})`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </li>
                        <li className="date">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Zona</span>
                                <select
                                    className="form-control w-75"
                                    name="zone_id"
                                    ref={textInput}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione una zona</option>
                                    {service.facility.zones.map((item) => (
                                        <option
                                            key={`zona` + item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </li>
                    </>
                )}
                {!!(!familiarDisable && showForm && user && service.with_booking) && (
                    <>
                        {service.with_multiple_booking && service.facility.facilities.length > 0 ? (
                            <li>
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>Seleccionar instalación</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <select
                                        className="form-select w-100"
                                        name="facility_id"
                                        ref={textInput}
                                        onChange={handleChange}
                                    >
                                        <option value="">{selectHourText}</option>
                                        {service.facility.facilities.map((item) => (
                                            <option
                                                key={`facility-`+item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </li>
                        ) : null}
                        {!service.with_multiple_booking || (service.with_multiple_booking && newOrder.facility_id != '') ? (
                            <>
                                <li className="date">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Fecha</span>
                                        <input
                                            type="date"
                                            className="form-control w-75 float-end"
                                            name="serviceDate"
                                            min={format(new Date(), "yyyy-MM-dd")}
                                            value={newOrder.serviceDate}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </li>
                                {newOrder.serviceDate &&  service.with_time ? (
                                    <li className="date">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>Hora</span>
                                            <select
                                                className="form-control w-75"
                                                name="serviceHour"
                                                ref={textInput}
                                                onChange={handleChange}
                                            >
                                                <option value="">{selectHourText}</option>
                                                {newOrder.options.map((item) => (
                                                    <option
                                                        key={item.value}
                                                        value={item.value}
                                                    >
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </li>
                                ) : null}
                            </>
                        ) : null}

                    </>
                )}
                {!familiarDisable && showForm && user && service.expedientable ? (
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Nº de expendiente</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <input
                                type="text"
                                name="expedient"
                                className="form-control w-100"
                                value={newOrder.expedient}
                                onChange={handleChange}
                            />
                        </div>
                    </li>
                ) : null}
                {!familiarDisable && showForm && user && service.with_iban ? (
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Nº de cuenta (IBAN)</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <textarea
                                type="text"
                                name="iban"
                                className="form-control w-100"
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </li>
                ) : null}
                {!familiarDisable && showForm && user && service.fields.length > 0
                    ? service.fields.map((field) => {
                        return (
                            <>
                                <li key={`familiar-` + field.id}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>{field.label}</span>
                                    </div>
                                    {field.description && (
                                        <>
                                            <div className="d-flex justify-content-between align-items-center my-2">
                                                <small>{field.description}</small>
                                            </div>
                                        </>
                                    )}
                                    {renderField(field)}
                                </li>
                            </>
                        );
                    })
                    : null}
                {!familiarDisable && showForm && user && newOrder.price > 0 && user?.cif !== null && user?.cif !== undefined ? (
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Comprar como</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <select
                                className="form-select w-100"
                                name="user_type"
                                onChange={handleChange}
                            >
                                <option
                                    key="user_type_particular"
                                    value="particular"
                                >
                                    Particular
                                </option>
                                <option
                                    key="user_type_business"
                                    value="business"
                                >
                                    Empresa
                                </option>
                            </select>
                        </div>
                    </li>
                ) : null}
                {!familiarDisable && showForm && user && service.with_notes ? (
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Observaciones</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <textarea
                                type="text"
                                name="notes"
                                className="form-control w-100"
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </li>
                ) : null}

                {!familiarDisable && showForm && user && min && (
                    <>
                        <li>
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Unidades</span>
                                <input
                                    type="number"
                                    name="units"
                                    className="form-control w-25"
                                    min={min ?? 1}
                                    max={max ?? 100000000}
                                    value={newOrder.units}
                                    onChange={handleChange}
                                />
                            </div>
                        </li>
                    </>
                )}
            </ul>
            {!!user ? (
                <>
                    {!familiarDisable && showForm ? (
                        <div className="btn-box">
                            {/* <a className='default-btn' role={role} onClick={createOrder} disabled={creating ? "disabled" : ""}>
                            {creating ?
                                <div className="spinner-border spinner-border-sm pr-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <i className='flaticon-price-tag'></i>
                            }
                            Comprar ahora<span></span>
                        </a> */}
                            <a
                                className="default-btn"
                                role={role}
                                onClick={addToCart}
                                disabled={creatingCart ? "disabled" : ""}
                            >
                                {creatingCart ? (
                                    <div
                                        className="spinner-border spinner-border-sm pr-5"
                                        role="status"
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    <i className="flaticon-shopping-cart"></i>
                                )}
                                Añadir a la cesta<span></span>
                            </a>
                        </div>
                    ) : null}
                </>
            ) : (
                <p className="mt-3 text-center">
                    <Link to="/signin">Inicia sesión</Link> para poder comprar.
                </p>
            )}

            <div className="services-share">
                <div className="share-info">
                    <span>
                        Compartir en redes <i className="flaticon-share"></i>
                    </span>
                    <ul className="social-link">
                        <li>
                            <a
                                href="https://www.facebook.com/"
                                className="d-block"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="bx bxl-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/"
                                className="d-block"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="bx bxl-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/"
                                className="d-block"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="bx bxl-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/"
                                className="d-block"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="bx bxl-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <Snackbar
                open={error !== null}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{width: "100%"}}
                >
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={success !== null}
                autoHideDuration={6000}
                onClose={handleCloseSuccess}
            >
                <Alert
                    onClose={handleCloseSuccess}
                    severity="success"
                    sx={{width: "100%"}}
                >
                    {success}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ServiceDetailsSidebar;
